const projectGrid = [{
        type: 'Design',
        title: 'Dashboard UI',
        content: 'Duis arcu suscipit eget',
        category: 'active',
        users: [{
                name: 'Eliza Hardin',
                profile: require('@/assets/images/users/avatar-4.jpg')
            },
            {
                name: 'Ronald Hatfield',
                profile: require('@/assets/images/users/avatar-5.jpg')
            }
        ]
    },
    {
        type: 'Design',
        title: 'Ecommerce App',
        content: 'Nemo enim ipsam sit',
        category: 'completed',
        users: [{
                name: 'Megan Seaton',
                profile: require('@/assets/images/users/avatar-4.jpg')
            },
            {
                name: 'Ronald Hatfield',
                profile: require('@/assets/images/users/avatar-5.jpg')
            },
            {
                name: 'Eliza Hardin',
                variant: 'bg-info'
            }
        ]
    },
    {
        type: 'Development',
        title: 'Calendar App',
        content: 'Itaque earum rerum ut',
        category: 'completed',
        users: [{
            name: 'Ronald Hatfield',
            profile: require('@/assets/images/users/avatar-5.jpg')
        }]
    },
    {
        type: 'Development',
        title: 'Kanban Board',
        content: 'Maecenas nec odio et ante',
        category: 'active',
        users: [{
            name: 'Frank Snow',
            variant: 'bg-primary'
        }]
    },
    {
        type: 'Development',
        title: 'Authentication',
        content: 'At vero eos et accusamus et',
        category: 'completed',
        users: [{
            name: 'Ronald Hatfield',
            profile: require('@/assets/images/users/avatar-5.jpg')
        }]
    },
    {
        type: 'Design',
        title: 'Redesign - Landing Page',
        content: 'Neque porro quisquam est',
        category: 'completed',
        users: [{
                name: 'Rosa Armstrong',
                variant: 'bg-info'
            },
            {
                name: 'Frank Snow',
                variant: 'bg-success'
            }
        ]
    },
    {
        type: 'Design',
        title: 'Brand Logo Design',
        content: 'Sed ut perspiciatis unde iste',
        category: 'active',
        users: [{
                name: 'Janna Johnson',
                profile: require('@/assets/images/users/avatar-1.jpg')
            },
            {
                name: 'Heather Ford',
                profile: require('@/assets/images/users/avatar-2.jpg')
            },
            {
                name: 'Eliza Hardin',
                variant: 'bg-info'
            },
            {
                name: 'Megan Seaton',
                profile: require('@/assets/images/users/avatar-4.jpg')
            }
        ]
    },
    {
        type: 'Development',
        title: 'Chat App',
        content: 'Quis autem vel eum iure',
        category: 'completed',
        users: [{
                name: 'Megan Seaton',
                profile: require('@/assets/images/users/avatar-4.jpg')
            },
            {
                name: 'Ronald Hatfield',
                profile: require('@/assets/images/users/avatar-5.jpg')
            },
            {
                name: 'Heather Ford',
                profile: require('@/assets/images/users/avatar-2.jpg')
            }
        ]
    }
];

export {
    projectGrid
};